import React from "react"
import { MemberCard } from "./MemberCard.jsx"
import { Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

export function memSort(a, b) {
    if (a.key < b.key) {
        return -1
    } else if (a.key > b.key) {
        return 1
    } else return 0
}

export function AllCards({ category, edges }) {
    if (!category || !edges) {
        return null
    }

    let listObj = {}
    edges.forEach(({ node: { frontmatter: fm } }) => {
        if (fm.category === category) {
            const slugArr = fm.slug.split("-")
            const lastLetter = fm.altlast
                ? fm.altlast.toLowerCase()
                : slugArr[slugArr.length - 1][0]

            if (!listObj[lastLetter]) listObj[lastLetter] = []
            listObj[lastLetter].push(fm)
        }
    })

    const memArr = [
        ...Object.entries(listObj).map(e => ({ key: e[0], list: e[1] })),
    ].sort(memSort)

    return (
        <div className="member-cards" data-testid="test-all-cards">
            <Helmet>
                <style type="text/css">
                    {`
                    .staff-card {
                        overflow: hidden;
                        border: none;
                        height: 100%;
                        max-height: 280px;
                    }
                    
                    .staff-card:hover .card-body {
                        margin-top: 0 !important;
                    }
                    
                    .staff-card .card-body {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        top: 0;
                        transition: margin 0.5s ease;
                        background: #0000008c;
                    }
                    
                    .az-list {
                        display: flex;
                        flex-wrap: wrap;
                        font-size: 17px;
                    }
                    
                    .az-list a.off {
                        pointer-events: none;
                        opacity: 0.8;
                    }
                    
                    .az-list a {
                        padding: 10px;
                    }
                    
                    `}
                </style>
            </Helmet>
            {memArr.map(e => (
                <div key={e.key} id={e.key.toUpperCase()}>
                    <h4 className="border-bottom p-3">{e.key.toUpperCase()}</h4>
                    <Row>
                        {e.list.map(id => {
                            return <MemberCard obj={id} key={id.slug} />
                        })}
                    </Row>
                </div>
            ))}
        </div>
    )
}
