import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Page } from "../../../components/elements"
import { DefaultHeader } from "../../../components/elements/Headers.jsx"
import { AllCards } from "../../../components/elements/members/AllCards.jsx"
import { AZList } from "../../../components/elements/members/AZList.jsx"
import { PageCenter } from "../../../components/elements/wrappers/PageCenter.jsx"

export default function Component() {
    const {
        allMarkdownRemark: { edges },
    } = useStaticQuery(
        graphql`
            {
                allMarkdownRemark(
                    filter: { fileAbsolutePath: { regex: "//members//" } }
                    sort: { fields: [frontmatter___fullName], order: [ASC] }
                ) {
                    edges {
                        node {
                            ...MemberFields
                        }
                    }
                }
            }
        `,
    )
    return (
        <Page title="Affiliate Scientists">
            <PageCenter bg="white">
                <DefaultHeader title="Affiliate Scientists" type="small" />
                <AZList category="Affiliate Scientists" edges={edges} />
                <AllCards category="Affiliate Scientists" edges={edges} />
            </PageCenter>
        </Page>
    )
}
