import React from "react"
import { Card, Button } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import ErrorBoundary from "../../errors/ErrorBoundary"

export function Component({ obj: member }) {
    const category = member.category.toLowerCase().replace(/ /, "-")

    return (
        <Card
            style={{ width: "14rem", height: "400px" }}
            className="m-3 staff-card"
            data-testid={"test-" + member.slug}
        >
            {member.featuredImage ? (
                <GatsbyImage
                    image={member.featuredImage.childImageSharp.gatsbyImageData}
                    alt={member.slug}
                />
            ) : null}
            <Card.Body className="text-light" style={{ marginTop: "80%" }}>
                <Card.Title data-testid="fullname">
                    {member.fullName}
                </Card.Title>
                <Card.Text style={{ display: "flex", flexDirection: "column" }}>
                    <span className="pb-3" data-testid="title">
                        {member.title}
                    </span>
                    {member.info?.map(({ label, value }, i) =>
                        ["Email", "Phone"].includes(label) ? (
                            <span key={i} data-testid={label.toLowerCase()}>
                                {value}
                            </span>
                        ) : null,
                    )}
                </Card.Text>
                <a
                    href={`/about/${category}/${member.slug}`}
                    data-testid="slug"
                >
                    <Button variant="egi">More Information</Button>
                </a>
            </Card.Body>
        </Card>
    )
}

export function MemberCard(props) {
    return (
        <ErrorBoundary
            fallback={{
                width: "224px",
                height: "280px",
            }}
        >
            <Component {...props} />
        </ErrorBoundary>
    )
}
