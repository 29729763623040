import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import ErrorBoundary from "../../errors/ErrorBoundary"

export function Component({ category, edges }) {
    if (!category || !edges) return null
    let list = []

    Object.keys(edges).map(e => {
        const member = edges[e].node.frontmatter
        const slugArr = member.slug.split("-")
        if (member.category == category) {
            list.push(slugArr[slugArr.length - 1][0].toUpperCase())
        }
    })

    return (
        <div className="az-list" style={{ justifyContent: "center" }}>
            {Array(26)
                .fill("")
                .map((_, i) => {
                    const key = String.fromCharCode(i + 65)
                    return (
                        <button
                            onClick={() => scrollTo("#" + key)}
                            key={i}
                            style={{
                                background: "none",
                                border: "none",
                                color: "var(--red1)",
                            }}
                            className={
                                !list.includes(key)
                                    ? "off text-dark mx-1"
                                    : "on mx-1"
                            }
                        >
                            {key}
                        </button>
                    )
                })}
        </div>
    )
}

export function AZList(props) {
    return (
        <ErrorBoundary
            fallback={{
                width: "100%",
                height: "40px",
            }}
        >
            <Component {...props} />
        </ErrorBoundary>
    )
}
